<template>
  <header class="main-header">
    <swiper
      lazy
      preloadImages
      navigation
      loop
      :modules="modules"
      :autoplay="{ delay: 8000 }"
      :pagination="{ clickable: true }"
      @swiper="initHeaderSwiper"
    >
      <SwiperSlide v-for="headerSlide in headerSlides" :key="headerSlide.id">
        <div class="header-slide">
          <img :data-src="require(`@/assets/headers/${headerSlide.image}`)" class="header-slide-image swiper-lazy" />
          <div class="header-slide-content">
            <div class="container">
              <div class="header-slide-content-inner">
                <div class="header-slide-content-inner-title">
                  <h2 v-html="headerSlide.title"></h2>
                </div>
                <div class="header-slide-content-inner-description">
                  <p v-html="headerSlide.description"></p>
                </div>
                <div
                  v-if="headerSlide.actionButton.moreDetails || headerSlide.actionButton.bookAnAppointment"
                  class="header-slide-content-inner-actions"
                >
                  <BtnMore
                    v-if="headerSlide.actionButton.moreDetails"
                    :to="headerSlide.actionButton.moreDetails"
                    size="md"
                  />
                  <BtnBookingOnline
                    v-if="headerSlide.actionButton.bookAnAppointment"
                    variant="outline-primary"
                    size="md"
                  />
                  <BtnBookingPhone
                    v-if="headerSlide.actionButton.bookAnAppointment"
                    variant="outline-primary"
                    size="md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </swiper>
  </header>
</template>
<script>
import { A11y, Autoplay, Lazy, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import BtnMore from "@/components/units/buttons/BtnMore";
import BtnBookingOnline from "@/components/units/buttons/BtnBookingOnline";

import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/lazy/lazy.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import BtnBookingPhone from "@/components/units/buttons/BtnBookingPhone";

export default {
  name: "MainHeader",
  components: {
    Swiper,
    SwiperSlide,
    BtnMore,
    BtnBookingOnline,
    BtnBookingPhone,
  },
  computed: {
    primaryMenuHeight() {
      const primaryMenuElement = document.querySelector(".primary-menu");
      return primaryMenuElement ? primaryMenuElement.clientHeight + "px" : 0 + "px";
    },
  },
  setup() {
    return {
      initHeaderSwiper: () => {},
      modules: [Navigation, Pagination, Autoplay, Lazy, A11y],
    };
  },
};
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

$primaryMenuHeight: v-bind(primaryMenuHeight);

.main-header {
  .header-slide {
    position: relative;

    &-image {
      width: 100%;
      height: 55vh;

      @include media-breakpoint-up(sm) {
        height: calc(100vh - #{$primaryMenuHeight});
      }
    }

    &-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($white, 0.8);

      @include media-breakpoint-up(sm) {
        background: none;
      }

      &-inner {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: $spacer;
        padding: $spacer * 2;

        @include media-breakpoint-up(sm) {
          top: 50%;

          gap: $spacer * 2;
          background: rgba($white, 0.8);
          border: $border-width $border-style $white;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          transform: translate(0, -50%);
          width: 50%;
          min-width: 50rem;
        }

        &-title {
          @include set-headings($font-size-level-6, $font-weight-bold);
          text-align: center;

          @include media-breakpoint-up(sm) {
            @include set-headings($font-size-level-7, $font-weight-bold);
            text-align: left;
          }
        }

        &-description {
          @include set-font-style($font-size-level-5, $font-weight-light);
          text-align: center;

          @include media-breakpoint-up(sm) {
            @include set-font-style($font-size-level-6, $font-weight-light);
            text-align: left;
          }
        }

        &-actions {
          display: flex;
          flex-direction: column;
          gap: $spacer;

          @include media-breakpoint-up(sm) {
            flex-direction: row;
          }
        }
      }
    }
  }
}
</style>
