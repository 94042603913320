<template>
  <SectionBlock class="banner">
    <div class="banner-title">Szeretnéd, ha gyermeked kipróbálná valamelyik elektromos kicsikocsit?</div>
    <div class="banner-content">Foglalj időpontot online vagy telefonon!</div>
    <div class="banner-action-buttons">
      <BtnBookingOnline class="btn-lg" />
      <BtnBookingPhone class="btn-lg btn-bg-light" />
    </div>
  </SectionBlock>
</template>

<script>
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import BtnBookingOnline from "@/components/units/buttons/BtnBookingOnline";
import BtnBookingPhone from "@/components/units/buttons/BtnBookingPhone";

export default {
  name: "SectionBanner",
  components: { SectionBlock, BtnBookingOnline, BtnBookingPhone },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.banner {
  background-blend-mode: multiply;
  background-color: rgba($gray-900, 0.7);
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: $section-padding 0;
  text-align: center;

  @include media-breakpoint-up(sm) {
    padding: $section-padding * 2 0;
    background-attachment: fixed;
  }

  &-title {
    @include set-font-style($font-size-level-6, $font-weight-bolder, $white, uppercase, $font-family-heading);
    text-shadow: 2px 2px $gray-900;

    @include media-breakpoint-up(sm) {
      @include set-font-style($font-size-level-7, $font-weight-bolder, $white, uppercase, $font-family-heading);
    }
  }

  &-content {
    @include set-font-style($font-size-level-5, $font-weight-light, $white);
    text-shadow: 2px 2px $gray-900;
    margin: $spacer 0;

    @include media-breakpoint-up(sm) {
      margin: 0 0 $spacer 0;

      @include set-font-style($font-size-level-7, $font-weight-light, $white);
    }
  }

  &-action-buttons {
    display: flex;
    flex-direction: column;
    gap: $spacer;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }
}
</style>
