<template>
  <div class="question-card">
    <div class="question-card-icon">
      <BootstrapIcon icon="question" />
    </div>
    <div class="question-card-title">{{ question.title }}</div>
    <div class="question-card-content">
      <ul>
        <li v-for="answer in question.answers" :key="answer">
          <BootstrapIcon icon="chevron-right" class="left" />
          {{ answer }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionCard",
  props: {
    question: Object,
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.question-card {
  position: relative;
  display: block;
  padding: $spacer;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  text-align: center;

  &-icon {
    position: relative;
    display: inline-block;
    margin: -1 * $spacer 0 0 0;
    padding: calc(#{$spacer} / 2);
    width: $spacer * 3.5;
    height: $spacer * 3.5;
    transform: translateY(-1 * $spacer);

    border-radius: 100%;
    background-color: $primary;

    .bi {
      margin: 0;
      @include set-font-style($font-size-level-8, $font-weight-bold, $white);
    }
  }

  &-title {
    position: relative;
    display: block;
    margin: 0 0 $spacer 0;

    @include set-font-style($font-size-level-5, $font-weight-bold, $gray-900, uppercase);
  }

  &-content {
    position: relative;
    display: block;

    @include set-font-style($font-size-level-4, $font-weight-light);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;

      @include media-breakpoint-up(sm) {
        text-align: left;
      }

      li {
        .bi {
          display: none;

          @include media-breakpoint-up(sm) {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>
